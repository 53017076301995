import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRef } from 'react';
import dynamic from 'next/dynamic';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import useIsInView from '@segment/utils/useInView';
import * as views from './LogoTiles.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var LogoGrid = dynamic(function () {
  return import("./LogoGrid");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("./LogoGrid")];
    }
  }
});

var BaseLogoTiles = function BaseLogoTiles(_ref) {
  var tokens = _ref.tokens;
  var wrapperRef = useRef(null);
  var isInView = useIsInView(wrapperRef, {
    options: {
      rootMargin: "200px"
    }
  });
  return _jsx(views.TilesGridWrapper, _objectSpread(_objectSpread({}, tokens), {}, {
    ref: wrapperRef,
    children: _jsx(views.TilesGrid, {
      children: isInView && _jsx(LogoGrid, {})
    })
  }));
};

export var LogoTiles = withDesignTokens(BaseLogoTiles, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      gradientColor: theme.palette.neutrals.cloud[100]
    };
  },
  dark: function dark(theme) {
    return {
      gradientColor: theme.palette.brand.darkAcai[700]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      gradientColor: theme.palette.twilio.gray[10]
    };
  }
});